<template>
  <div class="col-12 g">
    <div class="card">
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                      <th>
                        رقم المشروع
                      </th>
                      <th>
                        رقم القطعة
                      </th>
                      <th>
                        رقم الحجز
                      </th>
                      <th>
                        التاريخ
                      </th>
                      <th>
                        الحالة
                      </th>
                    </thead>
                    <tbody>
                       <tr v-for="order in orders" :key="order._id">
                        <td>
                            {{ order.item_id }}
                        </td>
                        <td>
                            {{ order.unit_id }}
                        </td>
                        <td>
                            {{ order.order_id }}
                        </td>
                        <td>
                            {{ order.date }}
                        </td>
                        <td>
                            <span class="btn btn-primary btn-sm" style="border-radius: 0px" v-if="order.status >= 0">
                                <i class="fa fa-check"></i>
                                انشاء امر حجز
                            </span>
                            <span class="btn btn-danger btn-sm" style="border-radius: 0px" v-if="order.status >= 1">
                                <i class="fa fa-check"></i>
                                جاري الحجز
                            </span>
                            <span class="btn btn-sm btn-outline-secondary" style="border-radius: 0px" v-if="order.status < 1">
                                <i class="fa fa-times"></i>
                                جاري الحجز
                            </span>
                            <span class="btn btn-success btn-sm" style="border-radius: 0px" v-if="order.status >= 2">
                                <i class="fa fa-check"></i>
                                تم الحجز
                            </span>
                            <span class="btn btn-sm btn-outline-secondary" style="border-radius: 0px" v-if="order.status < 2">
                                <i class="fa fa-times"></i>
                                تم الحجز
                            </span>
                            <span class="btn btn-sm btn-danger" style="border-radius: 0px" v-if="order.status == 6">
                                <i class="fa fa-times"></i>
                                فشل الحجز
                            </span>
                            <div v-if="order.status == 6">
                                <textarea name="" readonly v-model="order.reason" id="" cols="30" rows="10"></textarea>
                            </div>
                        </td>
                       </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BOverlay, BModal, VBModal, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            loading: false,
            user: JSON.parse(localStorage.getItem("user")),
            orders: []
        }
    },
    created(){
        var g = this;
        g.getProjects();
    },
    methods: {
        getProjects(){
            var g = this;
            g.loading = true;
            $.post(api + '/user/projects/orders', {
                jwt: g.user.jwt
            }).then(function(r){
                g.orders = r.response
            })
        }
    }
}
</script>

<style>

</style>